@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900");
@import url("https://fonts.googleapis.com/css2?family=Damion&family=Galada&display=swap");



:root {
  --brand-color: white;
  --font-color: black;

}
body{
  font-family: CircularXX;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./assets/fonts/CircularXX-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}


.App {
  text-align: center;
  /* font-family: "Merriweather", serif; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


#results-div-container {
  display: none;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* NAVBAR  */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

/* Test Utilities */

.Test_Div_Large_Height {
  width: 100%;
  height: 2000px;
  background-color: blue;
}

/* Input Options*/
.introduction-text > p {
  /* text-align: left; */
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #323232;
  font-family: CircularXX, Inter, Merriweather, Georgia, serif;
  margin: 0;
  margin-top: 83px;
}

.custom-textbox {
  font-family: CircularXX, Inter;
  width: 100%;
  font-weight: 500;
  /* max-width: 50%;  */
  padding: 15px 20px; /* Adjust the padding to match the image */
  /* border: 1px solid #4A4A4A; Light grey border */
  border: none;
  border-radius: 10px; /* Rounded borders */
  font-size: 16px; /* Adjust the size as needed */
  color: #9b9b9b; /* Light text color */
  /* margin-top: 20px; Add space above the text box */
  outline: none; /* Remove the default focus outline */
  /* background-color: #e8e8e8; */
  background-color: #f9f9f9;
  opacity: 0;
  height: 2em;
}

.custom-textbox::placeholder {
  color: #9b9b9b; /* Placeholder text color */
  /* font-style: italic; */
  font-family: Lato;
  font-size: 14px;
}

.displaySettingsTextDiv {
  /* width: 47%; */
  margin: auto;
}

.displaySettingsTextDiv > .displaySettingsText {
  /* margin-top: 20px; */
  margin-left: 14px;
  text-align: left;
  color: #777777;
  font-size: 14px;
  cursor: pointer;
  font-family: CircularXX,Inter;
}
.squares-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px; /* Space between squares */
  margin-bottom: 20px; /* Adjust as necessary */
  margin-top: 30px;
}

.square {
  padding-top: 100%; /* Maintain aspect ratio */
  background-color: #f0f0f0; /* Change the color as needed */
  position: relative;
  border-radius: 10px;
}

.square-description {
  display: none;
  padding-right: 15px;
  position: absolute;
  top: 15px; /* Adjust based on your design */
  left: 15px; /* Adjust based on your design */
  color: rgb(129, 129, 129); /* or any color you prefer */
  font-size: 1em; /* Adjust as needed */
  font-weight: normal;
  text-align: left;
  font-family: "Inter", sans-serif;
  /* width: calc(100% - 40px); Calculate the width minus padding */
  overflow: hidden; /* Hide overflow text */
  /* white-space: nowrap; No wrapping */
  text-overflow: ellipsis; /* Add an ellipsis for overflow */
}

.corner-text {
  position: absolute;
  bottom: 15px; /* Adjust as needed for top corner spacing */
  left: 20px; /* Adjust as needed for left corner spacing */
  color: #7d7d7d; /* or any color you prefer */
  font-size: 1.5em; /* Adjust as needed */
  font-weight: 500;
  text-align: left;
  font-family: Inter;

  /* background: linear-gradient(45deg, #ff89fc, #85d7ff); Example gradient */
  -webkit-background-clip: text;
  background-clip: text;
  color: white; /* This will reveal the gradient */
  /* Additional styling as needed */
}
@media (max-width: 1100px) {
  .squares-container {
    grid-template-columns: repeat(3, 1fr); /* 2 columns on smaller screens */
  }
}
@media (max-width: 900px) {
  .squares-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

.submit-wrapper {
  margin: 0 auto;
  text-align: left; /* Add this line to left-align content */
}

.submit-wrapper > * {
  text-align: left; /* Add this to ensure all child elements are left-aligned */
}

.input-div {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 5px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}



.info-icon {
  color: rgb(170, 170, 170);
  margin-left: 10px;
}

.upload-icon {
  /* margin-left: 10px; */
  cursor: pointer;
  font-size: 30px;
}

.upload-icon-main {
  color: rgb(163, 163, 163);
  cursor: pointer;
  font-size: 30px;
}

.upload-icon-div {
  padding: 10px;
}

.slider-container {
  width: 100%;
  margin: 30px auto;
}

.custom-slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 7px; /* Specified height */
  background: #e8e8e8; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (cross-browser) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border-radius: 10px;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #af00d6; /* Red background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Circular handle */
}

.custom-slider::-moz-range-thumb {
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: #f00; /* Red background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Circular handle */
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.slider-labels span {
  font-size: 14px; /* Adjust as needed */
}

.slider-caption {
  font-size: 15px; /* Adjust the font size as needed */
  color: #777777; /* Set the text color */
  /* margin-bottom: 8px; */

  text-align: left;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Customize the scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 7px;
  background-color: #f9f9f9; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #d8d8d8; /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded corners for the handle */
  /* border: 2px solid #f0f0f0; Optional border to create space between the handle and the track */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888888; /* Color when the user hovers over the handle */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-70%); /* Center the tooltip */
  margin-left: -60px; /* Use half of the width (120px/2) to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-slider .tooltiptext {
  transform: translateX(0) !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.videoSeekBar {
  accent-color: #d172e8;
}
.videoSeekBar:hover {
  opacity: 1;
}

.submit-button {
  background-color: #bd1fe0;
  height: 60px;
  /* line-height: 47px; */
  padding: 0 20px;
  color: #fff;
  border: 0;
  /* box-shadow: 0 10px 40px -10px rgba(191, 0, 255, 0.777); */
  transform: translateY(0);
  transition: all 0.2s, transform 0.5s;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;

  /* margin-bottom: 20px; */
  cursor: pointer;
  opacity: 0;
}

.submit-button:hover {
  background-color: #9400b5;
  box-shadow: 0 10px 40px -10px rgba(191, 0, 255, 0.977);
}

.slide {
  animation-name: slide;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes slide {
  0% {
    opacity: 1;
  }

  100% {
    transform: translateX(-3%);
    opacity: 0;
  }
}

.slide2 {
  animation-name: slide2;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-direction: reverse;
}

@keyframes slide2 {
  100% {
    opacity: 0;
    transform: translateX(3%);
  }
  50% {
    opacity: 0.9;
  }

  0% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  100%,
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.fade-in-out {
  animation: fadeInOut 3s ease-in-out infinite;
}

.suggested-topics-div > p {
  font-size: 25px;
  font-weight: 400;
  /* line-height: 40px; */
  color: #4a4a4a;
  font-family: Merriweather, Georgia, serif;
  /* font-family: Lato; */
  margin: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns with equal width */
  grid-gap: 20px; /* Space between items */
  /* Adjust the max-width and margin as needed */
  /* max-width: 800px; */
  margin: 40px auto;
  opacity: 0;
}

.grid-item {
  /* Additional styling such as background color, padding, etc. */

  overflow: hidden; /* Ensures the content fits neatly into the corners */
  /* position: relative; For positioning text over the image if needed */
  position: relative;
}

/* .grid-item > .image_div{
  background-color: rgb(188, 188, 188);
  border-radius: 10px; 
  aspect-ratio: 1 / 1;
  border: 1px solid rgb(158, 31, 200);
} */
.grid-item > p {
  text-align: left;
  font-family: CircularXX;  
  color: var(--font-color);
  ;
}

.grid-item img {
  width: 100%; /* Make images expand to cover the item */
  display: block; /* Remove any extra space below the image */
  border-radius: 10px; /* Rounded corners for images */
}

.item-text {
  text-align: center; /* Center the text */
  margin-top: 8px; /* Space above the text */
  /* Additional styling for the text */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
    /* z-index: 1; */
  }
  to {
    opacity: 1;
    transform: translateY(0);
    /* z-index: 0; */
   
  }
}

.fade-in-animation {
  animation: fadeIn 0.5s ease forwards;
}
.fade-in-animation-slow {
  animation: fadeIn 0.9s ease forwards;
}

.fade-in-animation-delay-1 {
  animation-delay: 0.2s;
}

.fade-in-animation-delay-2 {
  animation-delay: 0.4s;
}

.fade-in-animation-delay-3 {
  animation-delay: 0.6s;
}

.fade-in-animation-delay-4 {
  animation-delay: 0.8s;
}

.layout {
  display: flex;
  height: 100vh; /* Full viewport height */
}

.sidebar {
  height: 100%;
  font-family: Inter;
  overflow-y: auto;
  /* min-width: 250px; */
  max-width: 250px;
  /* background-color: #F7F7F7; */
  /* background-color: #f0f4f8; */
  background-color: #f9f9f9;

  position: relative;
  /* Other sidebar styles... */
}

.floating-sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  width: 250px;
  /* background-color: #f9f9f9; */
  z-index: 1000;
  overflow-y: auto;
  /* padding: 20px; */
  background-color: rgba(249, 249, 249, 0);
}

.sidebar-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: CircularXX;
  transition: transform 0.2s ease-in-out;
}

.sidebar-section:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.sidebar-thumbnail {
  width: 80px;
  /* height: 60px; */
  background-color: var(--brand-color);
  margin-right: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 4px;
  border: .5px solid rgb(209, 209, 209);
  /* box-shadow: 0 2px 6px rgba(192, 192, 192, 0.1), 0 4px 6px rgba(150, 150, 150, 0.1); */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.sidebar-section:hover .sidebar-thumbnail {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar-title {
  font-family: CircularXX, Inter, sans-serif;
  font-size: 14px;
  color: var(--font-color);
  flex-grow: 1;
  text-align: left;
  font-weight: 700;
}

.results-content {
  margin-left: 250px; /* Adjust based on the width of your sidebar */
}


.thumbnail-headline {
  font-size: 4px !important;
  margin-top: 7px !important;
  font-family: CircularXX;
  text-align: left;

}

.thumbnail-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  /* grid-gap: 20px; Space between items */
  grid-gap: 1px;
  /* Adjust the max-width and margin as needed */
  /* max-width: 800px; */
  /* margin: 40px auto; */
  opacity: 0;
}

.thumbnail-grid-item {
  overflow: hidden;
  
  position: relative;
}

.thumbnail-grid-item > .image_div > img{
  width: 100%;
  display: block;
  border-radius: 4px;
}
.thumbnail-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 2px !important;
  margin-top: 10px !important;
}

.thumbnail-grid-item {
}
.thumbnail-grid-item > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.thumbnail-grid-item > p {
  text-align: left;
  font-size: 3px !important;
  font-family: CircularXX;  
  color: var(--font-color);
  ;
}

/* Container to hold the sidebar and possibly the rest of the page content */
.sidebar-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

/* Sidebar expanded and collapsed styles */
.sidebar.expanded {
  width: 250px; /* Example sidebar width */
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 0;
  transition: width 0.3s ease;
  overflow: hidden; /* Prevent content from overflowing during transition */
}

/* Hamburger Menu */
.hamburger-menu {
  width: 0; /* Adjust based on the size of your icon */
  height: 40px; /* Adjust based on the size of your icon */
  display: flex;
  color: rgb(186, 186, 186);
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  cursor: pointer;
  /* Adjust margin or padding as needed */
}
.hamburger-menu > svg {
  position: relative;
  left: 20px;

  /* height: 25px; */
  font-size: 20px;
}
/* .hamburger-menu {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 101;
  cursor: pointer;
} */

.content {
  flex-grow: 1; /* Take up remaining space */
  overflow: auto; 
  /* padding: 20px; Space inside the content area */
  /* Adjust padding as needed */
  } 


.content-glass {
  flex-grow: 1;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8); 
  backdrop-filter: blur(50px); 
  min-height: 100vh;
}

.results-content-bg{
  background-color: var(--brand-color);
  background-image: none;
  
}

.yolo-bg{
  flex-grow: 1;
  overflow: auto;
  min-height: 100vh; /* Ensures full viewport height coverage */
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255); 
  background-image: url('../public/circle-scatter-haikei.png'); /* Path to the image in the public folder */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* This keeps the background fixed while scrolling */
  
}



.helpful-note {
  font-family: Lato;
}

.beta-text {
  color: #777777;
  font-family: Inter;
  font-size: 15px;
  margin-left: 5px;
}
.sidebar-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;
  padding: 0 25px; /* Adjust based on your sidebar padding */
  padding-top: 25px;
  /* margin: 15px; */
  /* margin-top: 25px; */
  /* position: fixed; */
}

.fixed-sidebar-footer {
  position: sticky;
  bottom: 0;
  height: 50px;
  background-color: #f9f9f9;
  z-index: 10;
}

.fixed-sidebar-header {
  position: sticky;
  top: 0;
  z-index: 10;
  padding-bottom: 20px;
  background-color: #f9f9f9;
}

.sidebar-logo {
  width: 35px; /* Adjust based on your logo's size */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer;
  display: block; /* Ensures the logo is block level to respect width/height */
}

.submit-icon {
  cursor: pointer;
  margin-left: 10px; /* Space between logo and icon */
  font-size: 20px; /* Adjust icon size as needed */
  color: #9d9d9d;
  margin-top: 10px;
}

.job-list {
  list-style: none;
  padding: 0;
  margin: 10px auto;
  width: 90%;
  font-family: CircularXX, Inter;
}

.job-list-header {
  margin-top: 20px;
  margin-left: auto;
  text-align: left;
  width: 90%;
  font-family: CircularXX, Inter;
  font-size: 14px;
  /* color: #9d9d9d; */
  color: #818181;
}

/* .job-item {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
} */

.job-item {
  text-align: left;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
  overflow: hidden; /* Ensure content that exceeds the box is hidden */
  white-space: nowrap; /* Keep the job ID on a single line */
  text-overflow: ellipsis; /* Add an ellipsis to indicate text overflow */
  max-width: 90%; /* Adjust as needed, ensures a margin of safety */
  font-size: 14px;
  position: relative;
}

.job-item:hover {
  background-color: #f9dfff; /* Light grey on hover */
}

.job-item.active {
  background-color: #e2e2e2; /* Light purple for active job */
  /* color: white; */
}

.hider {
  display: none;
}

.rename-job-icon {
  display: none;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.job-item:hover .rename-job-icon {
  display: inline;
}

#time-note {
  color: rgb(118, 118, 118);
  margin: 0 auto;
  margin-top: 20px;
  font-family: Lato;
  width: 576px;
}

#time-note-2 {
  color: rgb(118, 118, 118);
  margin: 0 auto;
  margin-top: 20px;
  font-family: Lato;
  width: 576px;
}

.video-js .vjs-tech {
  border-radius: 10px;
}

.video-js {
  border-radius: 10px;
}

.vjs-has-started .vjs-control-bar,
.vjs-audio-only-mode .vjs-control-bar {
  border-radius: 10px;
}

.logout-p {
  cursor: pointer;
  color: #818181; /* Light grey */
  position: relative;
  text-align: left;
  padding-top: 15px;
  padding-left: 25px;
  /* margin-left:30px; */
  /* margin-bottom:1px; */
  font-size: 14px;
  font-family: CircularXX, Inter;
}

.login-logo-placement {
  margin: auto;
}

.login-button {
  background-color: #af00d6;
  padding: 10px 20px;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  height: 47px;
  margin-top: 10px;
  /* box-shadow: 0 10px 40px -10px rgba(191, 0, 255, 0.777); */
  /* height: 47px;
  line-height: 47px;
  padding: 0 30px;
  color: #fff;
  border: 0;
  box-shadow: 0 10px 40px -10px rgba(191, 0, 255, 0.777);
  transform: translateY(0);
  transition: all .2s, transform .5s;
  border-radius: 5px;
  text-transform: uppercase;
  font-family:"Lato","Helvetica Neue",Arial,sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  opacity: 0; */
}

.login-signup-p {
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
  font-family: Lato;
  font-size: 22px;
  color: rgb(67, 67, 67);
}

.error-message {
  color: red;
  text-align: center;
  /* width: 0; */
}

.input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: none;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-family: Lato;
  font-size: 16px;
}

.videoSeekBar {
  width: 100%;
  cursor: pointer;
}

.new-video-div {
  width: 84%;
  margin: 0 auto;
  display: flex;
}

.grid-item {
  cursor: pointer;
}

.video-player-wrapper {
  margin-top: 30px;
  border-radius: 10px;
}

.new-video-button {
  background-color: #000000; /* Light grey background */
  border: 1px solid #e0e0e0; /* Slight border to match the image */
  border-radius: 6px; /* Rounded corners */
  padding: 10px 20px; /* Top and bottom padding, and a bit more on the sides */
  font-size: 16px; /* Adjust font size as needed */
  font-weight: 600;
  color: #ffffff; /* Grey font color similar to the one in the image */
  font-family: CircularXX, Inter, sans-serif; /* Assuming Lato is the font you're using */
  text-align: center; /* Center the text inside the button */
  cursor: pointer; /* Cursor changes to pointer to indicate it's clickable */
  box-shadow: none; /* No shadow for a flat design */
  outline: none; /* Remove the outline to match the design */
  transition: background-color 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
  margin-top: 75px;
  width: 100%;

}

.new-video-button:hover {
  background-color: #515151; /* Slightly darker background on hover */
  /* Optional: Add a subtle shadow on hover if you like */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
}

.new-video-button:active {
  background-color: #d5d5d5; /* Even darker for the active state (when the button is clicked) */
  /* Optional: Adjust the box shadow for the active state if added on hover */
}

.time-remaining-wrapper {
  text-align: right;
  font-family: Inter;
}

.share-button:hover {
  cursor: pointer;
}

.grid-item-highlight {
  background-color: purple;
  padding: 10px;
  color: white;
  border-radius: 10px;
}

.grid-item-now-playing {
  margin: 0 auto;
  text-align: left;
  margin-bottom: 15px;
}

@media (width <= 1100px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width <= 1250px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }

  canvas {
    width: 70% !important;
  }
}

@media (width <= 768px) {
  canvas {
    width: 100% !important;
  }
  .submit-wrapper {
    width: 100%;
    /* min-width:400px; */
    margin: 0 auto;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.video-js .vjs-progress-control {
  display: none !important;
}

.video-js .vjs-time-control {
  display: none !important;
}

.title-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Ensures the button aligns to the bottom */
  margin-bottom: 10px;
}

.current-title {
  /* Styles for the title, could include responsive adjustments */
  margin: 0;
  font-family: Inter;
  text-align: left;
  font-size: 20px;
  align-self: center;
  width: 90%;
  font-weight: 700;
  margin-bottom: 15px;
}

.title-edit-icon {
  margin-left: 15px;
  cursor: pointer;
}

.chakra-button {
  font-weight: 700 !important;
  font-family:CircularXX, Inter !important;
  align-self: center;
}

.select-box {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid teal;
  border-radius: 4px;
  cursor: pointer;
  /* Additional styles for alignment and spacing */
}
.select-box:checked {
  background-color: teal; /* or any color */
}

.hide {
  display: none;
}

.share-edit-buttons {
  cursor: pointer;
}

.shower {
  display: block !important;
}

/* Ensure the result-top-bar is styled properly */
.result-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space between elements */
  background-color: #fff;
  width: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 6px rgba(150, 150, 150, 0.1);

  position: sticky;
  top: 0px;
  z-index: 1000;
  padding-top: 7px;
  padding-bottom: 7px;
  
  
  
  /* background-color: var(--brand-color); */

}

.result-logo img {
  height: 40px; /* Adjust the height as needed */
  margin-left: 15px;
}

.header-text {
  margin-top: 325px;
  font-family:"CircularXX", "Inter", sans-serif; /* Ensure consistent font family */
  font-size: 56px; /* Adjust font size as needed */
  font-weight: 600;
  color: var(--font-color);/* Text color */
  text-align: center;
  line-height: 1.4;
  position: relative;
  z-index: 50;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.subheader-text {
  font-family: "Inter", sans-serif; /* Ensure consistent font family */
  font-size: 42px; /* Adjust font size as needed */
  font-weight: 600;
  color: var(--font-color); /* Text color */
  margin-left: 0; /* Align text with logo */
  text-align: left;
  max-width: 600px;
  opacity: 0;
  line-height: 1.4;
}

.divider {
  border: none;
  border-top: 1.5px solid rgb(219, 219, 219);
  margin: 20px auto; /* Add some space around the divider */
  max-width: 99%;
  margin-top: 90px;
  opacity: 0;
}

.divider-no-animate{
  border: none;
  border-top: 1.5px solid var(--font-color);
  margin: 20px auto; /* Add some space around the divider */
  max-width: 99%;
  margin-top: 150px;
  opacity: .2;
  
} 


.share-button {
  /* background-color: black !important; */
  /* color: white !important; */
  border: none;
  border-radius: 4px; /* Slight border radius */
  padding: 10px 20px; /* Adjust padding as needed */
  font-family:"CircularXX",  sans-serif;
}

.edit-text-button {
  border: none;
  margin-right: 10px; 
  cursor: pointer;
}

.generate-email-button {
  border: none;
  margin-right: 10px; 
  cursor: pointer;
}

/* .grid-item.selected-item {
  border: 3px solid #af00d6;
  box-shadow: 0 0 10px rgba(175, 0, 214, 0.5);
} */

.grid-item .image_div.unselected-thumbnail {
  opacity: 0.5;
}


.section-headline{
  margin-top: 150px;
  margin-bottom: 50px;
  font-family: CircularXX;

}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  aspect-ratio: 1 / 1;
  font-size: 1.5rem;
  margin: 1.25rem auto;
  position: relative;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  /* grid-template-columns: repeat(4, 1fr); */
  gap: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  height: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
}



.large-card-container {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(310px, 2fr));
  gap: 20px;
  margin-bottom: 20px;
  
}

.large-card {
  background-color: #ffffff;
  border-radius: 10px;
  height: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



.button-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.upload-button, .link-button {
  flex: 1;
  border: none;
  padding: 10px 16px;
  font-family: CircularXX, Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.upload-button {
  background: linear-gradient(135deg, #8a2be2, #4b0082);
}

.link-button {
  background-color: #ffffff;
  color: #333;
}

.upload-button:hover {
  background: linear-gradient(135deg, #9b4dff, #5c1a99);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.link-button:hover {
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.upload-button:active {
  background: linear-gradient(135deg, #7b1fa2, #4a148c);
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.link-button:active {
  background-color: #f0f0f0;
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.upload-button svg, .link-button svg {
  margin-right: 8px;
}


.card-section-titles{
  font-family: CircularXX, Inter, Merriweather, Georgia, serif;
  font-size:20px;
  font-weight: 600;
  line-height: 40px;
  color: #323232;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}


.card-section-container{
  margin-top: 20px;
  
}


.input-group {
  display: flex;
  margin-top: 15px;
  /* gap: 10px;  This adds space between the inputs */
}


.large-card-wrapper, .card-wrapper {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.large-card, .card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensure the image doesn't overflow during transformation */
}


.large-card-wrapper:hover .large-card, .card-wrapper:hover .card {
  transform: scale(1.05);
}

.large-card img, .card img {
  transition: transform 0.3s ease;
}

.large-card-wrapper:hover img, .card-wrapper:hover img {
  transform: scale(1.1);
}

.card-caption {
  transition: color 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
}

.large-card-wrapper:hover .card-caption, .card-wrapper:hover .card-caption {
  color: #8a2be2; /* Change to your preferred highlight color */
}
.master-template {
  padding: 20px;
}

.master-template .chakra-button {
  margin-bottom: 20px;
}

.master-template .chakra-icon-button {
  margin-left: 10px;
}
